import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Dev/Personal/kathryn-jones-nutrition/src/components/default-page-layout.js";
import { FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact`}</h1>
    <ul>
      <li parentName="ul">
        <a href="mailto:kjnutrition@outlook.com"><FaEnvelope mdxType="FaEnvelope" /><span>Email</span></a>
      </li>
      <li parentName="ul">
        <a href="https://www.facebook.com/kathrynjonesnutrition" target="_blank"><FaFacebook mdxType="FaFacebook" /><span>Facebook</span></a>
      </li>
      <li parentName="ul">
        <a href="https://www.instagram.com/_kjnutrition_" target="_blank"><FaInstagram mdxType="FaInstagram" /><span>Instagram</span></a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      